<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('settings.advance.title')"
        :subTitle="$t('settings.advance.desc')"
    />
    <section class="wameed-dashboard-page-content_body">
      <b-row class="px-2">
        <b-col lg="12" md="12" class="px-0">
          <b-card
              no-body
              class="cursor-pointer wameed-card py-5 px-5"
              @click="changePassword = true"
          >
            <div
                class="
                d-flex
                py-1
                px-1
                align-items-center
                justify-content-between
              "
            >
              <h4 class="h-panel__title text-reg-20">
                {{ $t('common.change_password') }}
              </h4>
              <arrow-down-icon/>
            </div>
          </b-card>
          <change-password
              :visible="changePassword"
              @close="changePassword = false"
          />
        </b-col>
        <b-col lg="12" md="12" class="px-0">
          <b-card
              no-body
              class="cursor-pointer wameed-card py-5 px-5"
              @click="updateTax = true;taxValue=getSettingsInfo.tax"
          >
            <div
                class="
                d-flex
                py-1
                px-1
                align-items-center
                justify-content-between
              "
            >
              <h4 class="h-panel__title text-reg-20">
                {{ $t('settings.advance.tax') }}
              </h4>
              {{ getSettingsInfo.tax }} %
            </div>
          </b-card>
          <update-tax
              :visible="updateTax"
              :currentTax="taxValue"
              @updated="afterUpdateTax"
              @close="updateTax=false"
          />
        </b-col>
        <b-col lg="12" md="12" class="px-0">
          <details-form/>
        </b-col>
        <b-col lg="12" md="12" class="px-0">
          <social-media-form v-if="getSettingsInfo"/>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

import DetailsForm from '@/components/pages/setting/company-information/DetailsForm.vue';
import SocialMediaForm from '@/components/pages/setting/company-information/SocialMediaForm.vue';
import PageHeader from '@/components/PageHeader.vue';
import ChangePassword from '@/components/pages/setting/company-information/ChangePassword.vue';
import UpdateTax from "@/views/pages/settings/advance/components/updateTax";

export default {
  components: {
    UpdateTax,
    DetailsForm,
    SocialMediaForm,
    PageHeader,
    ChangePassword,
  },
  data() {
    return {
      taxValue: '0',
      updateTax: false,
      changePassword: false,
    };
  },
  computed: {
    ...mapGetters({
      getSettingsInfo: 'getSettingsInfo',
    }),
  },

  methods: {
    ...mapActions({
      loadSettingsInfo: 'loadSettingsInfo',
      updateContacts: 'updateContacts',

    }),

    async afterUpdateTax() {
      this.updateTax = false;
      await this.loadSettingsInfo();
    }
  },
  async beforeMount() {
    await this.loadSettingsInfo();
  },
};
</script>
