<template>
  <!-- <div class="wameed-filter-modal  "> -->
  <validation-observer
      ref="form"
      #default="{ invalid }"
      tag="form"
      style="overflow: visible"
  >
    <b-modal
        id="wameed-modal"
        v-model="show"
        header-class="py-5 px-5"
        body-class="py-5 px-5"
        title=""
        no-fade
        scrollable
    >
      <template #modal-header>
        <div class="d-flex justify-content-between align-items-center w-100">
          <h4 class="text-medium-20">
            {{ $t('settings.advance.tax_change') }}
          </h4>
          <b-button
              variant="white"
              size="sm"
              class="close"
              @click="show = false"
          >
            <close-icon/>
          </b-button>
        </div>
      </template>
      <!-- <wameed-form refs="" @submitAction="submitJob">
               <template slot="form-content" slot-scope="{ invalid }"> -->
      <div class="py-1 px-1">
        <text-input
            id="old-password"
            v-model="tax"
            :label="$t('settings.advance.tax')"
            field-classes="mb-5"
            input-classes="text-med-14 text-font-main "
            name="oldPassword"
            rules="required|regex:^[1-9]\d*(\.\d+)?$"
            :placeholder="$t('settings.advance.tax_placeholder')"
            is-append
            :icon="'percent-icon'"
        />
      </div>
      <template slot="modal-footer">
        <div class="d-flex justify-content-between align-items-center w-100">
          <wameed-btn
              classes="  text-med-14 text-white rounded-10"
              :title="$t('common.save_edited')"
              :disabled="invalid"
              type="submit"
              variant="main"
              @submitAction="submitChangedPassword()"
          />
          <wameed-btn
              classes="  text-med-14 text-font-secondary rounded-10 "
              :title="$t('common.cancel')"
              type="button"
              variant="gray"
              @submitAction="closeModal()"
          />
        </div>
      </template>
      <!-- </template>
      </wameed-form> -->
    </b-modal>
  </validation-observer>
</template>

<script>
import {ValidationObserver} from 'vee-validate';
import WameedBtn from '@/components/WameedBtn.vue';
import TextInput from '@/components/TextInput.vue';
import {mapActions, mapGetters} from 'vuex';

export default {
  components: {
    WameedBtn,
    TextInput,
    ValidationObserver,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    currentTax: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      tax: '0',
    };
  },
  watch: {
    currentTax(newVal) {
      if (newVal != null) {
        this.tax = newVal.toString();
      }
    },
  },
  computed: {
    ...mapGetters({
      getProfileInfo: 'getProfileInfo',
    }),
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    ...mapActions({
      updateTax: 'updateTax',
    }),
    submitChangedPassword() {
      const data = {
        tax: this.tax,
      };
      if (data) {
        this.updateTax(data).then(() => {
          this.$emit('updated');
        });
      }
      this.show = false;
    },
    closeModal() {
      (this.tax = 0),
          (this.show = false);
    },
  },
};
</script>
